"use strict";
/**
 * ObjectDesigner client logic module
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * ObjectDesigner client logic class
 * @export
 * @class ObjectDesigner
 */
class ObjectDesigner {
    /**
     * ObjectDesigner onInitialize event handler
     * @static
     * @param {akioma.swat.GraphEditor} graphEditor
     * @memberof ObjectDesigner
     */
    static onInitialize(container) {
        const designer = container.getObject('SimpleSwatLayoutDesign');
        const graphEditor = container.getObject('SimpleSwatGraphEditor');
        const propertyGrid = container.getObject('MainInsAttributePropertyGrid');
        const dataSource = container.getDataSource('ActiveMasterBE');
        const objectGrid = container.getGrid('ObjectMasterGrid');
        graphEditor.controller.dhx.addEventListener('drop', (evt) => {
            this.onDrop(objectGrid, designer, evt);
        });
        graphEditor.controller.dhx.addEventListener('droppanel', (evt) => {
            this.onPanelDrop(objectGrid, designer, evt);
        });
        graphEditor.controller.dhx.addEventListener('selectcell', (evt) => {
            this.onSelectCell(designer, evt);
        });
        graphEditor.controller.dhx.addEventListener('insertbefore', (evt) => {
            this.onInsertBefore(designer, graphEditor, propertyGrid, dataSource, evt);
        });
        graphEditor.controller.dhx.addEventListener('add', (evt) => {
            this.onAdd(designer, graphEditor, propertyGrid, dataSource, evt);
        });
        graphEditor.controller.dhx.addEventListener('movebefore', (evt) => {
            this.onMoveBefore(designer, graphEditor, propertyGrid, dataSource, evt);
        });
        graphEditor.controller.dhx.addEventListener('move', (evt) => {
            this.onMove(designer, graphEditor, propertyGrid, dataSource, evt);
        });
        graphEditor.controller.dhx.addEventListener('delete', (evt) => {
            this.onDelete(designer, graphEditor, propertyGrid, dataSource, evt);
        });
    }
    /**
     * onDrop event handler
     * @static
     * @param {akioma.swat.Grid} grid
     * @param {akioma.swat.SwatObject} designer
     * @param {MouseEvent} evt
     * @memberof ObjectDesigner
     */
    static onDrop(grid, designer, evt) {
        const { dragData, dropData: { guid: dropGuid } } = evt.detail;
        const tree = designer.controller.dhx.root.dataObj.cdata.a.dataObj;
        const source = grid.controller.dhx;
        const sourceData = grid.controller.dataSource.dhx.data.pull;
        const sourceId = Object.keys(sourceData).find(id => (sourceData[id].objectmasterguid === dragData.objectmasterguid));
        const target = tree;
        const targetData = designer.controller.dhx.getService('UIState').pull;
        const targetId = Object.keys(targetData).find(id => (targetData[id].guid === dropGuid));
        tree.callEvent('onDrag', [sourceId, targetId, null, source, target]);
    }
    /**
     * onPanelDrop event handler
     * @static
     * @param {akioma.swat.Grid} grid
     * @param {akioma.swat.SwatObject} designer
     * @param {MouseEvent} evt
     * @memberof ObjectDesigner
     */
    static onPanelDrop(grid, designer, evt) {
        const { dragData, dropParentData: { guid: dropParentGuid }, dropLayoutPosition } = evt.detail;
        const tree = designer.controller.dhx.root.dataObj.cdata.a.dataObj;
        const source = grid.controller.dhx;
        const sourceData = grid.controller.dataSource.dhx.data.pull;
        const sourceId = Object.keys(sourceData).find(id => (sourceData[id].objectmasterguid === dragData.objectmasterguid));
        const target = tree;
        const targetData = designer.controller.dhx.getService('UIState').pull;
        const targetParentId = Object.keys(targetData).find(id => (targetData[id].guid === dropParentGuid));
        // since the dhtmlx layout designer preview pane was removed
        // the code below is needed to normalize target data
        Object.keys(targetData).filter(id => (targetData[id].$parent == targetParentId)).map(id => (targetData[id])).reduce((ret, data) => {
            if (data.ui === 'empty')
                ret = data.id = data.position;
            else if (data.position && isNaN(data.position))
                ret = data.position;
            return ret;
        }, '');
        const targetId = Object.keys(targetData).find(id => (targetData[id].$parent == targetParentId && targetData[id].id === dropLayoutPosition));
        tree.callEvent('onDrag', [sourceId, targetId, null, source, target]);
    }
    /**
     * Select cell event handler
     * @static
     * @param {akioma.swat.SwatObject} designer
     * @param {MouseEvent} evt
     * @memberof ObjectDesigner
     */
    static onSelectCell(designer, evt) {
        const { guid } = evt.detail;
        if (!guid)
            return;
        const service = designer.controller.dhx.getService('UIState');
        const activeItem = Object.values(service.pull).find((item) => item.guid === guid);
        if (!activeItem)
            return;
        const id = activeItem.$id;
        if (!id)
            return;
        service.setCursor(id);
    }
    /**
     * Insert before event handler
     * @static
     * @param {akioma.swat.SwatObject} designer
     * @param {akioma.swat.SwatObject} graphEditor
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @param {MouseEvent} evt
     * @memberof ObjectDesigner
     */
    static onInsertBefore(designer, graphEditor, propertyGrid, dataSource, evt) {
        const { dragData: source, dropData: target } = evt.detail;
        this.add(designer, graphEditor, propertyGrid, dataSource, source, target, true);
    }
    /**
     * Add event handler
     * @static
     * @param {akioma.swat.SwatObject} designer
     * @param {akioma.swat.SwatObject} graphEditor
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @param {MouseEvent} evt
     * @memberof ObjectDesigner
     */
    static onAdd(designer, graphEditor, propertyGrid, dataSource, evt) {
        const { dragData: source, dropData: target } = evt.detail;
        this.add(designer, graphEditor, propertyGrid, dataSource, source, target, false);
    }
    /**
     * Move before event handler
     * @static
     * @param {akioma.swat.SwatObject} designer
     * @param {akioma.swat.SwatObject} graphEditor
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @param {MouseEvent} evt
     * @memberof ObjectDesigner
     */
    static onMoveBefore(designer, graphEditor, propertyGrid, dataSource, evt) {
        const { dragData: source, dropData: target } = evt.detail;
        this.move(designer, graphEditor, propertyGrid, dataSource, source, target, true);
    }
    /**
     * Move event handler
     * @static
     * @param {akioma.swat.SwatObject} designer
     * @param {akioma.swat.SwatObject} graphEditor
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @param {MouseEvent} evt
     * @memberof ObjectDesigner
     */
    static onMove(designer, graphEditor, propertyGrid, dataSource, evt) {
        const { dragData: source, dropData: target } = evt.detail;
        this.move(designer, graphEditor, propertyGrid, dataSource, source, target, false);
    }
    /**
     * Delete event handler
     * @static
     * @param {akioma.swat.SwatObject} designer
     * @param {akioma.swat.SwatObject} graphEditor
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @param {MouseEvent} evt
     * @memberof ObjectDesigner
     */
    static onDelete(designer, graphEditor, propertyGrid, dataSource, evt) {
        const { guid } = evt.detail;
        this.delete(designer, graphEditor, propertyGrid, dataSource, guid);
    }
    /**
     * Ribbon delete event handler
     * @static
     * @param {akioma.swat.Ribbon} ribbon
     * @memberof ObjectDesigner
     */
    static onRibbonDelete(ribbon) {
        const container = ribbon.window;
        const designer = container.getObject('SimpleSwatLayoutDesign');
        const graphEditor = container.getObject('SimpleSwatGraphEditor');
        const propertyGrid = container.getObject('MainInsAttributePropertyGrid');
        const dataSource = container.getDataSource('ActiveMasterBE');
        const guid = graphEditor.controller.dhx.state.selectedGuid;
        this.delete(designer, graphEditor, propertyGrid, dataSource, guid);
    }
    /**
     * Ribbon trim event handler
     * @static
     * @param {akioma.swat.Ribbon} ribbon
     * @memberof ObjectDesigner
     */
    static onRibbonTrim(ribbon) {
        const container = ribbon.window;
        const designer = container.getObject('SimpleSwatLayoutDesign');
        const graphEditor = container.getObject('SimpleSwatGraphEditor');
        const propertyGrid = container.getObject('MainInsAttributePropertyGrid');
        const dataSource = container.getDataSource('ActiveMasterBE');
        this.trimNewColumns(designer, graphEditor, propertyGrid, dataSource);
    }
    /**
     * Add or insert before an instance from a master
     * @private
     * @static
     * @param {akioma.swat.SwatObject} designer
     * @param {akioma.swat.SwatObject} graphEditor
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @param {*} source
     * @param {*} target
     * @param {boolean} insertBefore
     * @memberof ObjectDesigner
     */
    static add(designer, graphEditor, propertyGrid, dataSource, source, target, insertBefore) {
        return __awaiter(this, void 0, void 0, function* () {
            const tree = designer.controller.dhx.root.dataObj.cdata.a.dataObj;
            const store = dataSource.controller.getStore('eSmartObjectInstance');
            const data = Object.keys(store.data.pull).map(key => store.data.pull[key]);
            const guid = window.uuid();
            let idx = 1;
            let name = source.objectname;
            const splitName = name.split('.');
            name = splitName[splitName.length - 1];
            while (data.find(rec => rec.instancename === name)) {
                idx++;
                let objectName = source.objectname;
                if (source.objectname.includes('.')) {
                    const splitName = source.objectname.split('.');
                    objectName = splitName[splitName.length - 1];
                }
                name = `${objectName}${idx}`;
            }
            if (insertBefore) {
                const seq = target.objectsequence;
                data.filter(rec => rec.parentinstanceguid === target.parentinstanceguid).filter(rec => rec.objectsequence >= seq).forEach(rec => {
                    rec.objectsequence++;
                    store.update(rec.id, rec);
                });
                store.add({
                    id: guid,
                    instancedescription: '',
                    instancename: name,
                    containerobjectmasterguid: target.containerobjectmasterguid,
                    objectmasterguid: source.objectmasterguid,
                    objectsequence: seq,
                    layoutposition: '',
                    objectmastername: source.objectname || source.objectmastername,
                    objectinstanceguid: guid,
                    instancetypename: source.objecttypename,
                    parentinstanceguid: target.parentinstanceguid,
                    pageguid: ''
                }, guid);
            }
            else {
                const seq = data.filter(rec => rec.parentinstanceguid === target.objectinstanceguid || target.objectmasterguid).reduce((ret, rec) => Math.max(ret, rec.objectsequence), 0) + 1;
                store.add({
                    id: guid,
                    instancedescription: '',
                    instancename: name,
                    containerobjectmasterguid: target.containerobjectmasterguid || target.objectmasterguid,
                    objectmasterguid: source.objectmasterguid,
                    objectsequence: seq,
                    layoutposition: '',
                    objectmastername: source.objectname || source.objectmastername,
                    objectinstanceguid: guid,
                    instancetypename: source.objecttypename,
                    parentinstanceguid: target.objectinstanceguid || '',
                    pageguid: ''
                }, guid);
            }
            // await PropertyGrid.fetchNewAttributes(propertyGrid, dataSource, guid);
            designer.controller.newInstances.push(guid);
            tree.callEvent('onItemAdd', [source, target, guid, name, insertBefore]);
            this.refreshData(graphEditor);
        });
    }
    /**
     * Move an instance
     * @static
     * @param {akioma.swat.SwatObject} designer
     * @param {akioma.swat.SwatObject} graphEditor
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @param {*} source
     * @param {*} target
     * @param {boolean} moveBefore
     * @memberof ObjectDesigner
     */
    static move(designer, graphEditor, propertyGrid, dataSource, source, target, moveBefore) {
        return __awaiter(this, void 0, void 0, function* () {
            const tree = designer.controller.dhx.root.dataObj.cdata.a.dataObj;
            const store = dataSource.controller.getStore('eSmartObjectInstance');
            const data = Object.keys(store.data.pull).map(key => store.data.pull[key]);
            const rec = data.find(rec => rec.objectinstanceguid === source.objectinstanceguid);
            if (moveBefore) {
                const seq = target.objectsequence;
                data.filter(rec => rec.parentinstanceguid === target.parentinstanceguid).filter(rec => rec.objectsequence >= seq).forEach(rec => {
                    rec.objectsequence++;
                    store.update(rec.id, rec);
                });
                rec.objectsequence = seq;
                rec.parentinstanceguid = target.parentinstanceguid;
                store.update(rec.id, rec);
            }
            else {
                const seq = data.filter(rec => rec.parentinstanceguid === target.objectinstanceguid || target.objectmasterguid).reduce((ret, rec) => Math.max(ret, rec.objectsequence), 0) + 1;
                rec.objectsequence = seq;
                rec.parentinstanceguid = target.objectinstanceguid || '';
                store.update(rec.id, rec);
            }
            tree.callEvent('onItemMove', [source, target, moveBefore]);
            this.refreshData(graphEditor);
        });
    }
    /**
     * Delete instances
     * @private
     * @static
     * @param {akioma.swat.SwatObject} designer
     * @param {akioma.swat.SwatObject} graphEditor
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @param {string} guid
     * @memberof ObjectDesigner
     */
    static delete(designer, graphEditor, propertyGrid, dataSource, guid) {
        const tree = designer.controller.dhx.root.dataObj.cdata.a.dataObj;
        const instanceStore = dataSource.controller.getStore('eSmartObjectInstance');
        // const masterStore = dataSource.controller.getStore('eSmartObjectMaster');
        const data = Object.keys(instanceStore.data.pull).map(key => instanceStore.data.pull[key]);
        const rec = data.find(rec => rec.objectinstanceguid === guid);
        // const state = propertyGrid.controller.customData.state;
        if (!rec)
            return;
        // children must be deleted before their parents
        this.deleteChildren(instanceStore, rec.objectinstanceguid);
        instanceStore.remove(rec.id);
        // delete state.updatedRows[rec.id];
        this.deleteEmptyParentBlocks(tree, instanceStore, rec.parentinstanceguid);
        tree.callEvent('onItemDelete', [guid]);
        this.refreshData(graphEditor);
    }
    /**
     * Trim new columns
     * @private
     * @static
     * @param {akioma.swat.SwatObject} designer
     * @param {akioma.swat.SwatObject} graphEditor
     * @param {akioma.swat.SwatObject} propertyGrid
     * @param {akioma.swat.DataSource} dataSource
     * @memberof ObjectDesigner
     */
    static trimNewColumns(designer, graphEditor, propertyGrid, dataSource) {
        const tree = designer.controller.dhx.root.dataObj.cdata.a.dataObj;
        const store = dataSource.controller.getStore('eSmartObjectInstance');
        const data = Object.keys(store.data.pull).map(key => store.data.pull[key]);
        const parentsByGuid = data.reduce((ret, rec) => {
            ret[rec.parentinstanceguid] = 1;
            return ret;
        }, {});
        const parents = Object.keys(parentsByGuid).map(key => key);
        parents.forEach(parent => {
            const children = data.filter(rec => rec.parentinstanceguid === parent).sort(({ objectsequence: a }, { objectsequence: b }) => (a - b));
            // const state = propertyGrid.controller.customData.state;
            while (children.length > 0 && children[0].instancetypename.toLowerCase() === 'swatnewcolumn') {
                const child = children[0];
                store.remove(child.id);
                // delete state.updatedRows[child.id];
                tree.callEvent('onItemDelete', [child.objectinstanceguid]);
                children.splice(0, 1);
            }
            while (children.length > 0 && children[children.length - 1].instancetypename.toLowerCase() === 'swatnewcolumn') {
                const child = children[children.length - 1];
                store.remove(child.id);
                // delete state.updatedRows[child.id];
                tree.callEvent('onItemDelete', [child.objectinstanceguid]);
                children.splice(children.length - 1, 1);
            }
            this.deleteEmptyParentBlocks(tree, store, parent);
        });
        this.refreshData(graphEditor);
    }
    /**
     * Recursively deletes children
     * @private
     * @static
     * @param {*} store The dhtmlx data store
     * @param {string} guid The parent guid
     * @memberof ObjectDesigner
     */
    static deleteChildren(store, guid) {
        const data = Object.keys(store.data.pull).map(key => store.data.pull[key]);
        data.filter(rec => rec.parentinstanceguid === guid).forEach(rec => {
            // children must be deleted before their parents
            this.deleteChildren(store, rec.objectinstanceguid);
            store.remove(rec.id);
        });
    }
    /**
     * Deletes empty parent blocks starting with the passed parent
     * @private
     * @static
     * @param {*} tree The dhtmlx designer tree
     * @param {*} store The dhtmlx data store
     * @param {string} guid The parent guid
     * @memberof ObjectDesigner
     */
    static deleteEmptyParentBlocks(tree, store, guid) {
        const data = Object.keys(store.data.pull).map(key => store.data.pull[key]);
        const rec = data.find(rec => rec.objectinstanceguid === guid && rec.instancetypename.toLowerCase() === 'swatblock');
        // const state = propertyGrid.controller.customData.state;
        if (!rec)
            return;
        if (!data.find(rec => rec.parentinstanceguid === guid)) {
            store.remove(rec.id);
            // delete state.updatedRows[rec.id];
            tree.callEvent('onItemDelete', [rec.objectinstanceguid]);
            this.deleteEmptyParentBlocks(tree, store, rec.parentinstanceguid);
        }
    }
    /**
     * Refresh data
     * @private
     * @static
     * @param {akioma.swat.SwatObject} graphEditor
     * @memberof ObjectDesigner
     */
    static refreshData(graphEditor) {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
            this.refreshTimeout = null;
        }
        this.refreshTimeout = setTimeout(() => {
            graphEditor.controller.refreshData();
            this.refreshTimeout = null;
        }, 50);
    }
}
exports.default = ObjectDesigner;
